import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, CardComponent, CheckboxComponent, InputComponent, LoadingComponent, PageComponent } from 'mis-component-library';
import { AppUserService } from 'src/app/services/app-user/app-user.service';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    ButtonComponent,
    CardComponent,
    CheckboxComponent,
    CommonModule,
    FormsModule,
    InputComponent,
    LoadingComponent,
    PageComponent,
    ReactiveFormsModule,
    TranslateModule
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss'
})
export class UserDetailsComponent implements OnInit {
  protected readonly form = new FormGroup({
    id: new FormControl<number | null>(null),
    title: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    firstName: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    surname: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    telephone: new FormControl<string>('', { nonNullable: true }),
    mobile: new FormControl<string>('', { nonNullable: true }),
    email: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    // eslint-disable-next-line no-magic-numbers
    role: new FormControl<number>(3),
    active: new FormControl<boolean>(true, { nonNullable: true }),
  });
  protected readonly isEditing: boolean;
  protected isLoading = true;

  constructor(
    private readonly userService: AppUserService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    this.isEditing = activatedRoute.snapshot.paramMap.get('id') !== null;
  }

  public async ngOnInit(): Promise<void> {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id !== null) {
      const user = await this.userService.get(id);
      this.form.patchValue(user);
    }

    this.isLoading = false;
  }

  protected async back(): Promise<void> {
    if (this.form.dirty) {
      if (confirm('You have unsaved changes. Are you sure you want to leave?')) {
        await this.router.navigate(['../..'], { relativeTo: this.activatedRoute });
      }
    } else {
      await this.router.navigate(['../..'], { relativeTo: this.activatedRoute });
    }
  }

  protected async save(): Promise<void> {
    if (!this.form.valid) { throw new Error('Form must be valid to save.'); }
    if (this.isEditing) {
      const value = this.form.getRawValue();
      if (value.id == null) { throw new Error('Form must be valid to save.'); }
      
      // TODO: implement the call to the userService to update

      await this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
    } else {
      await this.userService.create(this.form.getRawValue());
      await this.router.navigate(['../../../'], { relativeTo: this.activatedRoute });
    }
  }
}