import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { TenantService } from '../tenant/tenant.service';
import { ValuationRunOutputsResponse } from 'src/types/valuation-run-outputs-response';
import { ValuationRunOutput } from 'src/types/valuation-run-output';
import { GetS3PresignedUrlResponse } from 'src/types/get-s3-presigned-response';
import { CustomHeaders } from 'src/types/custom-headers';

@Injectable({
  providedIn: 'root'
})
export class ValuationService {

  private valuationRunOutputs: BehaviorSubject<ValuationRunOutput[]> = new BehaviorSubject<ValuationRunOutput[]>([]);
  public valuationRunOutputs$ = this.valuationRunOutputs.asObservable();

  private presignedDownloadUrl: Subject<string> = new Subject<string>();
  public presignedDownloadUrl$ = this.presignedDownloadUrl.asObservable();

  private readonly fetchingValuationHistories = new BehaviorSubject<boolean>(true);
  public fetchingValuationHistories$ = this.fetchingValuationHistories.asObservable()

  private readonly fetchingPresignedUrl = new Subject<boolean>();
  public fetchingPresignedUrl$ = this.fetchingPresignedUrl.asObservable()

  constructor(
    private http: HttpClient,
    private tenantService: TenantService
  ) { }

  public async getValuationRunOutputs(): Promise<void> {
    this.fetchingValuationHistories.next(true);
    const url = await this.tenantService.getApiUrl();
    const response = await firstValueFrom(this.http.get<ValuationRunOutputsResponse>(`${url}ValuationRunOutputs`));

    this.valuationRunOutputs.next(response.valuationRunOutputs);
    this.fetchingValuationHistories.next(false);
  }

  public async getValuationRunOutput(valuationRunId: number): Promise<void> {
    this.fetchingValuationHistories.next(true);
    const url = await this.tenantService.getApiUrl();
    const response = await firstValueFrom(this.http.get<ValuationRunOutputsResponse>(`${url}ValuationRunOutputs?valuationRunId=`+ valuationRunId));
  
    this.valuationRunOutputs.next(response.valuationRunOutputs);
    this.fetchingValuationHistories.next(false);
  }

  public async getPresignedDownloadUrl(s3key: string): Promise<void> {
    this.fetchingPresignedUrl.next(true);
    const url = await this.tenantService.getApiUrl();
    const presignedUrlResponse = await firstValueFrom(this.http.get<GetS3PresignedUrlResponse>(`${url}ValuationRunPresignedDownloadURL?valuationRunOutputKey=`+s3key));

    this.presignedDownloadUrl.next(presignedUrlResponse.url);
    this.fetchingPresignedUrl.next(false);
  }

  public async getValuationOutputFile(url: string): Promise<Blob> {

    // const jwtToken = await this.getAuthSessionJwtToken();
    
    const headers = new HttpHeaders().append(CustomHeaders.skipInterceptor, 'true');
   
    const resp = await firstValueFrom(this.http.get(url, { responseType: 'blob', headers: headers }));

    return resp;
  }
}