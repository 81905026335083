<div class="container">
  <img src="assets/img/Reval.png" alt="Reval logo">
  <h1>Property Import Guide</h1>
  <hr />
  <p class="intro bold-font">Below is a list of the fields included in the upload template, an explanation of their
    purpose and
    any limitations
    on the data that the fields can include.</p>
  <div class="grid">
    <div class="row header">
      <div class="col-2 bold-font">Field</div>
      <div class="col-3 bold-font">Description</div>
      <div class="col-1 bold-font">Data Type</div>
      <div class="col-6 bold-font">Data Options</div>
    </div>
    <div class="row">
      <div class="col-2">External Reference</div>
      <div class="col-3">An unique identifier for this property.</div>
      <div class="col-1">Text</div>
      <div class="col-6"><i>Optional</i></div>
    </div>
    <div class="row">
      <div class="col-2">Original Price</div>
      <div class="col-3">The original price of the property.</div>
      <div class="col-1">Number</div>
      <div class="col-6">Must be equal to or greater than 0.</div>
    </div>
    <div class="row">
      <div class="col-2">Original Price Date</div>
      <div class="col-3">The date the Original Price was achieved.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Formatted as <i>dd/mm/yyyy</i></div>
    </div>
    <div class="row">
      <div class="col-2">Construction Type</div>
      <div class="col-3">The construction medium for the property</div>
      <div class="col-1">Text</div>
      <div class="col-6">Available options:
        <ul>
          <li>Brick</li>
          <li>Wood</li>
          <li>Other</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Property Type</div>
      <div class="col-3">The type of property being valued.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Available options:
        <ul>
          <li>Semi-Detached</li>
          <li>Detached</li>
          <li>Mid-Terrace</li>
          <li>Apartment</li>
          <li>Garage</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Asset Origin</div>
      <div class="col-3">Source of the property acquisition.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Available options:
        <ul>
          <li>Development</li>
          <li>Purchase</li>
          <li>Gift</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Build Date</div>
      <div class="col-3">The date which the property was built.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Formatted as <i>dd/mm/yyyy</i></div>
    </div>
    <div class="row">
      <div class="col-2">Date Received</div>
      <div class="col-3">The date which the property was acquired.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Formatted as <i>dd/mm/yyyy</i></div>
    </div>
    <div class="row">
      <div class="col-2">Owning Body</div>
      <div class="col-3">The Owning Body for this property.</div>
      <div class="col-1">Text</div>
      <div class="col-6"><i>Optional</i>
        <p>Maxmimum 100 characters in length.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Bedrooms</div>
      <div class="col-3">The number of bedrooms within the property.</div>
      <div class="col-1">Number</div>
      <div class="col-6">Must be equal to or greater than 0.</div>
    </div>
    <div class="row">
      <div class="col-2">Condition</div>
      <div class="col-3">Indicates the overall condition of the property.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Available options:
        <ul>
          <li>Good</li>
          <li>Average</li>
          <li>Bad</li>
          <li>Un-Modernised</li>
          <li>Very Good</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Cost of Outstanding Repairs</div>
      <div class="col-3">The current cost of outstanding repairs for the property.</div>
      <div class="col-1">Number</div>
      <div class="col-6">Must be equal to or greater than 0.</div>
    </div>
    <div class="row">
      <div class="col-2">Financing Instrument Name</div>
      <div class="col-3">The Financial Instrument associated with the purchase of this property.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Must be one of the Financial Instrument External Reference values from the Reval Configuration.
      </div>
    </div>
    <div class="row">
      <div class="col-2">Tenants Rights</div>
      <div class="col-3">The tenants' rights associated with the property.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Available options:
        <ul>
          <li>Right to buy</li>
          <li>Right to acquire</li>
          <li>None</li>
          <li>Shared ownership</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Line 1</div>
      <div class="col-3">The property address line 1.</div>
      <div class="col-1">Text</div>
      <div class="col-6">
        <i>Optional</i>
        <p>Maxmimum 100 characters in length.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Line 2</div>
      <div class="col-3">The property address line 2.</div>
      <div class="col-1">Text</div>
      <div class="col-6">
        <i>Optional</i>
        <p>Maxmimum 100 characters in length.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Line 3</div>
      <div class="col-3">The property address line 3.</div>
      <div class="col-1">Text</div>
      <div class="col-6">
        <i>Optional</i>
        <p>Maxmimum 100 characters in length.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Line 4</div>
      <div class="col-3">The property address line 4.</div>
      <div class="col-1">Text</div>
      <div class="col-6">
        <i>Optional</i>
        <p>Maxmimum 100 characters in length.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Line 5</div>
      <div class="col-3">The property address line 5.</div>
      <div class="col-1">Text</div>
      <div class="col-6">
        <i>Optional</i>
        <p>Maxmimum 100 characters in length.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Postcode</div>
      <div class="col-3">The postcode for the property.</div>
      <div class="col-1">Text</div>
      <div class="col-6">
        <p>Maxmimum 10 characters in length.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Region</div>
      <div class="col-3">The geographic region for the property.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Available options:
        <ul class="multi-column">
          <li>Avon</li>
          <li>Bedfordshire</li>
          <li>Berkshire</li>
          <li>Buckinghamshire</li>
          <li>Cambridgeshire</li>
          <li>Cheshire</li>
          <li>Cleveland</li>
          <li>Cornwall</li>
          <li>Cumbria</li>
          <li>Derbyshire</li>
          <li>Devon</li>
          <li>Dorset</li>
          <li>Durham</li>
          <li>East Sussex</li>
          <li>Essex</li>
          <li>Gloucestershire</li>
          <li>Greater London</li>
          <li>Greater Manchester</li>
          <li>Hampshire</li>
          <li>Hereford & Worcestershire</li>
          <li>Hertfordshire</li>
          <li>Humberside</li>
          <li>Isle of Wight</li>
          <li>Kent</li>
          <li>Lancashire</li>
          <li>Leicestershire</li>
          <li>Lincolnshire</li>
          <li>Merseyside</li>
          <li>Norfolk</li>
          <li>North Yorkshire</li>
          <li>Northamptonshire</li>
          <li>Northumberland</li>
          <li>Nottinghamshire</li>
          <li>Oxfordshire</li>
          <li>Shropshire</li>
          <li>Somerset</li>
          <li>South Yorkshire</li>
          <li>Staffordshire</li>
          <li>Suffolk</li>
          <li>Surrey</li>
          <li>Tyne & Wear</li>
          <li>Warwickshire</li>
          <li>West Midlands</li>
          <li>West Sussex</li>
          <li>West Yorkshire</li>
          <li>Wiltshire</li>
          <li>Derry</li>
          <li>Londonderry</li>
          <li>Antrim</li>
          <li>Tyrone</li>
          <li>Down</li>
          <li>Armagh</li>
          <li>Fermanagh</li>
          <li>Cavan</li>
          <li>Donegal</li>
          <li>Monaghan</li>
          <li>Cork</li>
          <li>Kerry</li>
          <li>Clare</li>
          <li>Limerick</li>
          <li>Tipperary</li>
          <li>Waterford</li>
          <li>Carlow</li>
          <li>Dublin</li>
          <li>Kildare</li>
          <li>Kilkenny</li>
          <li>Laois</li>
          <li>Longford</li>
          <li>Louth</li>
          <li>Meath</li>
          <li>Offaly</li>
          <li>Westmeath</li>
          <li>Wexford</li>
          <li>Wicklow</li>
          <li>Sligo</li>
          <li>Mayo</li>
          <li>Galway</li>
          <li>Leitrim</li>
          <li>Roscommon</li>
          <li>Unknown-UK</li>
          <li>Unkonwn-Republic of Ireland</li>
          <li>Unknown-Northern Ireland</li>
          <li>Aberdeenshire</li>
          <li>Angus</li>
          <li>Argyll</li>
          <li>Ayrshire</li>
          <li>Banffshire</li>
          <li>Berwickshire</li>
          <li>Buteshire</li>
          <li>Caithness</li>
          <li>Clackmannanshire</li>
          <li>Dumfriesshire</li>
          <li>Dunbartonshire</li>
          <li>East Lothian</li>
          <li>Fife</li>
          <li>Inverness-shire</li>
          <li>Kincardineshire</li>
          <li>Kinross-shire</li>
          <li>Kirkcudbrightshire</li>
          <li>Lanarkshire</li>
          <li>Midlothian</li>
          <li>Moray</li>
          <li>Nairnshire</li>
          <li>Orkney</li>
          <li>Peebleshire</li>
          <li>Perthshire</li>
          <li>Renfrewshire</li>
          <li>Ross and Cromarty</li>
          <li>Roxburghshire</li>
          <li>Selkirkshire</li>
          <li>Shetland</li>
          <li>Stirlingshire</li>
          <li>Sutherland</li>
          <li>West Lothian</li>
          <li>Wigtownshire</li>
          <li>Isle of Anglesey</li>
          <li>Gwynedd</li>
          <li>Conwy</li>
          <li>Denbighshire</li>
          <li>Flintshire</li>
          <li>Wrexham</li>
          <li>Ceredigion</li>
          <li>Powys</li>
          <li>Pembrokeshire</li>
          <li>Carmarthenshire</li>
          <li>Swansea</li>
          <li>Neath Port Talbot</li>
          <li>Bridgend</li>
          <li>Vale of Glamorgan</li>
          <li>Rhondda Cynon Taff</li>
          <li>Cardiff</li>
          <li>Merthyr Tydfil</li>
          <li>Caerphilly</li>
          <li>Newport</li>
          <li>Torfaen</li>
          <li>Blaenau Gwent</li>
          <li>Monmouthshire</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Country</div>
      <div class="col-3">The country in which the property is located.</div>
      <div class="col-1">Text</div>
      <div class="col-6">
        <p>This value should be set according to the region above.</p>
        <p>Available options:</p>
        <ul>
          <li>UK</li>
          <li>Republic of Ireland</li>
          <li>England</li>
          <li>Northern Ireland</li>
          <li>Scotland</li>
          <li>Wales</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Last Valuation</div>
      <div class="col-3">The most recent valuation price for the property.</div>
      <div class="col-1">Number</div>
      <div class="col-6">Must be equal to or greater than 0.</div>
    </div>
    <div class="row">
      <div class="col-2">Last Valuation Date</div>
      <div class="col-3">The date which the most recent valuation was obtained.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Formatted as <i>dd/mm/yyyy</i></div>
    </div>
    <div class="row">
      <div class="col-2">Market Rent</div>
      <div class="col-3">The estimated amount that the property could be rented for in the current market conditions.
      </div>
      <div class="col-1">Number</div>
      <div class="col-6"><i>Optional</i><p>If provided then the value must be equal to or greater than zero.</p></div>
    </div>
    <div class="row">
      <div class="col-2">Achieved Rent</div>
      <div class="col-3">The actual amount that is being paid by the tenant for the property.</div>
      <div class="col-1">Number</div>
      <div class="col-6"><i>Optional</i><p>If provided then the value must be equal to or greater than zero.</p></div>
    </div>
    <div class="row">
      <div class="col-2">Internal Area Sqft</div>
      <div class="col-3">The internal area of the property measured square feet.</div>
      <div class="col-1">Number</div>
      <div class="col-6"><i>Optional</i><p>If provided then the value must be equal to or greater than zero.</p></div>
    </div>
    <div class="row">
      <div class="col-2">Tenant Rights Qualification Date</div>
      <div class="col-3">The start date from which any RTA / RTB / Shared Ownership benefits are calculated.</div>
      <div class="col-1">Text</div>
      <div class="col-6"><i>Optional</i><p>Formatted as <i>dd/mm/yyyy</i></p></div>
    </div>
    <div class="row">
      <div class="col-2">Garden Size</div>
      <div class="col-3">An indication of the size of the property's main garden area.</div>
      <div class="col-1">Text</div>
      <div class="col-6">Available options:
        <ul>
          <li>None</li>
          <li>Less than 0.1 Acres</li>
          <li>0.1 - 0.5 Acres</li>
          <li>0.5 - 1.0 Acres</li>
          <li>Above 1.0 Acres</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Significant View</div>
      <div class="col-3">An indication of the views available from the property.</div>
      <div class="col-1">Text</div>
      <div class="col-6">
        <p>Available options:</p>
        <ul>
          <li>Normal</li>
          <li>Good</li>
          <li>Excellent</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-2">Shared Ownership Percent</div>
      <div class="col-3">The percentage of the shared ownership tenancy agreement owned by the tenant.</div>
      <div class="col-1">Number</div>
      <div class="col-6"><i>Optional</i>
        <p>Must be between 0 and 100.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-2">RTA Discount Location</div>
      <div class="col-3">The location for which the Right-To-Acquire discount should be obtained.</div>
      <div class="col-1">Text</div>
      <div class="col-6">
        <p>Available options - do not use the value displayed in the brackets:</p>
        <ul>
          <li>Bath and North East Somerset</li>
          <li><i>(Bedfordshire)</i> Bedford, Mid Bedfordshire, South Bedfordshire</li>
          <li><i>(Berkshire)</i> Reading, Slough, Windsor and Maidenhead, Wokingham</li>
          <li><i>(Berkshire)</i> Bracknell Forest, West Berkshire</li>
          <li>Blackburn with Darwen</li>
          <li>Blackpool</li>
          <li>Bournemouth</li>
          <li>Brighton and Hove</li>
          <li>Bristol</li>
          <li><i>(Buckinghamshire)</i> Chiltern, South Buckinghamshire</li>
          <li><i>(Buckinghamshire)</i> Aylesbury Vale, Wycombe
          <li><i>(Cambridgeshire)</i> Cambridge</li>
          <li><i>(Cambridgeshire)</i> South Cambridge</li>
          <li><i>(Cambridgeshire)</i> East Cambridgeshire, Huntingdonshire</li>
          <li><i>(Cambridgeshire)</i> Fenland</li>
          <li><i>(Cheshire)</i> Macclesfield</li>
          <li><i>(Cheshire)</i> Chester, Congleton, Vale Royal</li>
          <li><i>(Cheshire)</i> Crewe and Nantwich, Ellesmere Port and Neston</li>
          <li><i>(Cornwall)</i> Isles of Scilly</li>
          <li><i>(Cornwall)</i> Carrick</li>
          <li><i>(Cornwall)</i> Caradon, Kerrier, North Cornwall, Penwith, Restormel</li>
          <li><i>(Cumbria)</i> South Lakeland</li>
          <li><i>(Cumbria)</i> Allerdale, Barrow-in-Furness, Carlisle, Copeland, Eden</li>
          <li>Darlington</li>
          <li>Derby</li>
          <li><i>(Derbyshire)</i> Derbyshire Dales, High Peak</li>
          <li><i>(Derbyshire)</i> Amber Valley, Bolsover, Chesterfield, Erewash, North East Derbyshire, South Derbyshire</li>
          <li><i>(Devon)</i> South Hams, West Devon</li>
          <li><i>(Devon)</i> East Devon, Exeter, Mid Devon, North Devon, Teignbridge, Torridge</li>
          <li><i>(Dorset)</i> Christchurch, East Dorset</li>
          <li><i>(Dorset)</i> North Dorset, Purbeck, West Dorset</li>
          <li><i>(Dorset)</i> Weymouth and Portland</li>
          <li>Durham</li>
          <li>East Riding of Yorkshire</li>
          <li><i>(East Sussex)</i> Eastbourne</li>
          <li><i>(East Sussex)</i> Wealden</li>
          <li><i>(East Sussex)</i> Hastings, Lewes, Rother</li>
          <li><i>(Essex)</i> Brentwood, Epping Forest</li>
          <li><i>(Essex)</i> Basildon, Chelmsford, Harlow, Uttlesford</li>
          <li><i>(Essex)</i> Castle Point, Colchester, Maldon, Rochford</li>
          <li><i>(Essex)</i> Braintree, Tendring</li>
          <li><i>(Gloucestershire)</i> Cheltenham, Cotswold</li>
          <li><i>(Gloucestershire)</i> Forest of Dean, Gloucester, Stroud, Tewkesbury</li>
          <li><i>(Greater London)</i> Barnet, Bexley, Brent, Bromley, Camden, City of London, Croydon, Ealing, Enfield</li>
          <li><i>(Greater London)</i> Greenwich, Hackney, Hammersmith and Fulham, Haringey, Harrow, Hillingdon</li>
          <li><i>(Greater London)</i> Hounslow, Islington, Kensington and Chelsea, Kingston upon Thames, Lambeth</li>
          <li><i>(Greater London)</i> Lewisham, Merton, Richmond upon Thames, Southwark, Sutton, Tower Hamlets</li>
          <li><i>(Greater London)</i> Wandsworth, Westminster</li>
          <li><i>(Greater London)</i> Havering, Newham, Redbridge, Waltham Forest</li>
          <li><i>(Greater London)</i> Barking and Dagenham</li>
          <li><i>(Greater Manchester)</i> Stockport</li>
          <li><i>(Greater Manchester)</i> Manchester, Trafford</li>
          <li><i>(Greater Manchester)</i> Bolton, Bury, Oldham, Rochdale, Salford, Tameside, Wigan</li>
          <li>Halton</li>
          <li><i>(Hampshire)</i> Hart, Rushmoor, Winchester</li>
          <li><i>(Hampshire)</i> Basingstoke and Deane, East Hampshire</li>
          <li><i>(Hampshire)</i> Eastleigh, Fareham, Gosport, Havant, New Forest, Test Valley</li>
          <li>Hartlepool</li>
          <li>Herefordshire</li>
          <li><i>(Hertfordshire)</i> Broxbourne, Dacorum, Hertsmere, St. Albans, Three Rivers, Watford, Welwyn Hatfield</li>
          <li><i>(Hertfordshire)</i> East Hertfordshire, North Hertfordshire, Stevenage</li>
          <li>Isle of Wight</li>
          <li><i>(Kent)</i> Sevenoaks</li>
          <li><i>(Kent)</i> Tonbridge and Malling, Tunbridge Wells</li>
          <li><i>(Kent)</i> Dartford, Gravesham, Maidstone</li>
          <li><i>(Kent)</i> Ashford, Canterbury, Dover, Shepway, Swale, Thanet</li>
          <li>Kingston-upon-Hull</li>
          <li><i>(Lancashire)</i> Ribble Valley</li>
          <li><i>(Lancashire)</i> Burnley, Chorley, Fylde, Hyndburn, Lancaster, Pendle, Preston, Rossendale</li>
          <li><i>(Lancashire)</i> South Ribble, West Lancashire, Wyre</li>
          <li>Leicester</li>
          <li><i>(Leicestershire)</i> Harborough</li>
          <li><i>(Leicestershire)</i> Blaby, Charnwood, Hinckley and Bosworth, Melton</li>
          <li><i>(Leicestershire)</i> North West Leicestershire, Oadby and Wigston</li>
          <li>Lincolnshire</li>
          <li>Luton</li>
          <li>Medway</li>
          <li><i>(Merseyside)</i> Knowsley, Liverpool, Sefton, St Helens, Wirral</li>
          <li>Middlesbrough</li>
          <li>Milton Keynes</li>
          <li>Norfolk</li>
          <li><i>(Northamptonshire)</i> Daventry, Northampton, South Northamptonshire</li>
          <li><i>(Northamptonshire)</i> Corby, East Northamptonshire, Kettering, Wellingborough</li>
          <li>North East Lincolnshire</li>
          <li>North Lincolnshire</li>
          <li>North Somerset</li>
          <li>Northumberland</li>
          <li><i>(North Yorkshire)</i> Harrogate</li>
          <li><i>(North Yorkshire)</i> Craven, Hambleton, Richmondshire, Ryedale, Selby</li>
          <li><i>(North Yorkshire)</i> Scarborough</li>
          <li>Nottingham</li>
          <li><i>(Nottinghamshire)</i> Rushcliffe</li>
          <li><i>(Nottinghamshire)</i> Ashfield, Bassetlaw, Broxtowe, Gedling, Mansfield, Newark and Sherwood</li>
          <li><i>(Oxfordshire)</i> Oxford</li>
          <li><i>(Oxfordshire)</i> South Oxfordshire, West Oxfordshire, Vale of White Horse</li>
          <li><i>(Oxfordshire)</i> Cherwell</li>
          <li>Peterborough</li>
          <li>Plymouth</li>
          <li>Poole</li>
          <li>Portsmouth</li>
          <li>Redcar and Cleveland</li>
          <li>Rutland</li>
          <li><i>(Shropshire)</i> Bridgnorth, Shrewsbury and Atcham, South Shropshire</li>
          <li><i>(Shropshire)</i> Oswestry, North Shropshire</li>
          <li><i>(Somerset)</i> Taunton Deane</li>
          <li><i>(Somerset)</i> Mendip, Sedgemoor, South Somerset, West Somerset</li>
          <li>Southampton</li>
          <li>Southend-on-Sea</li>
          <li>South Gloucestershire</li>
          <li><i>(South Yorkshire)</i> Sheffield</li>
          <li><i>(South Yorkshire)</i> Barnsley, Doncaster, Rotherham</li>
          <li><i>(Staffordshire)</i> Lichfield</li>
          <li><i>(Staffordshire)</i> Cannock Chase, East Staffordshire, Newcastle-under-Lyme, South Staffordshire</li>
          <li><i>(Staffordshire)</i> Stafford, Staffordshire Moorlands, Tamworth</li>
          <li>Stockton-on-Tees</li>
          <li>Stoke-on-Trent</li>
          <li><i>(Suffolk)</i> St Edmundsbury</li>
          <li><i>(Suffolk)</i> Babergh, Forest Heath, Ipswich, Mid Suffolk, Suffolk Coastal</li>
          <li><i>(Suffolk)</i> Waveney</li>
          <li>Surrey</li>
          <li>Swindon</li>
          <li>Telford and the Wrekin</li>
          <li>Thurrock</li>
          <li>Torbay</li>
          <li>Tyne and Wear</li>
          <li>Warrington</li>
          <li><i>(Warwickshire)</i> Stratford-upon-Avon</li>
          <li><i>(Warwickshire)</i> Warwick</li>
          <li><i>(Warwickshire)</i> North Warwickshire, Nuneaton and Bedworth, Rugby</li>
          <li><i>(West Midlands)</i> Solihull</li>
          <li><i>(West Midlands)</i> Birmingham</li>
          <li><i>(West Midlands)</i> Coventry</li>
          <li><i>(West Midlands)</i> Dudley, Sandwell, Walsall, Wolverhampton</li>
          <li><i>(West Sussex)</i> Adur, Chichester, Crawley, Horsham, Mid Sussex</li>
          <li><i>(West Sussex)</i> Arun, Worthing</li>
          <li><i>(West Yorkshire)</i> Leeds</li>
          <li><i>(West Yorkshire)</i> Bradford, Calderdale, Kirklees, Wakefield</li>
          <li><i>(Wiltshire)</i> Salisbury</li>
          <li><i>(Wiltshire)</i> Kennett, North Wiltshire</li>
          <li><i>(Wiltshire)</i> West Wiltshire</li>
          <li><i>(Worcestershire)</i> Malvern Hills, Worcester, Wychavon</li>
          <li><i>(Worcestershire)</i> Bromsgrove, Redditch</li>
          <li><i>(Worcestershire)</i> Wyre Forest</li>
          <li>York</li>          
        </ul>
      </div>
    </div>
  </div>
</div>