import { Router, UrlTree } from "@angular/router";
import { inject } from "@angular/core";
import { Environment } from "src/types/environment";
import { ENV } from "src/providers/environment.provider";
import { LoginService } from "login";
import { DEV_LOGIN_CONFIG } from "../app-routing.module";
import { UserRoleService } from "../services/user-role/user-role.service";
import { Subject, takeUntil } from "rxjs";
import { UserRole } from "src/types/user-role";

export class AuthGuard {

  public static async canLoadAppGuard(): Promise<UrlTree | boolean> {
    const router = inject(Router);
    const validationResult = await AuthGuard.validateUser(router);
    if (validationResult != null) { return validationResult; }

    return true;
  }

  private static async validateUser(router: Router): Promise<UrlTree | boolean | null> {
    const environment = inject<Environment>(ENV);
    const loginService: LoginService = inject(LoginService);
    if (!await loginService.getUserEmail()) {
      if (!environment.loginHost) { return router.createUrlTree([DEV_LOGIN_CONFIG.loginPath]); }

      location.href = `${environment.loginHost}/login?redirect=${location.href}`;
      return false;
    }
    return null;
  }

  public static async canConfigure() : Promise<UrlTree | boolean | null> {
    
    const router = inject(Router);
    const unsubscribe$ = new Subject<void>();
    const userRoleService: UserRoleService = inject(UserRoleService);
    let userRole: UserRole|null = null;
    userRoleService.userRole$.pipe(takeUntil(unsubscribe$)).subscribe((role: UserRole|null) => {
      userRole = role;
    });

    await userRoleService.getUserRole();

    if(userRole != null && (userRole as UserRole).roleId == 1) {
      return true;
    } 
    else {
      return router.navigateByUrl('/app/not-authorised');
    }
  }
}