import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ENV } from 'src/providers/environment.provider';
import { CustomHeaders } from 'src/types/custom-headers';
import { Environment } from 'src/types/environment';
import { LsApplicationTenant } from 'src/types/ls-application-tenant';
import { LsApplicationTenantsResponse } from 'src/types/ls-application-tenants-response';
import { LsLicense } from 'src/types/ls-license';
import { LsLicenseTenantConfig } from 'src/types/ls-license-tenant-config';
import { LoginService } from 'login';

const LICENSE_EXPIRE_DURATION = 10000;

interface CognitoSsoConfig {
  tenantId: string;
  tenantName: string;
  sso: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private license?: Promise<LsLicense>;
  private tenant?: Promise<CognitoSsoConfig>;

  constructor(
    @Inject(ENV) private readonly env: Environment,
    private readonly http: HttpClient,
    private readonly loginService: LoginService
  ) { }

  /**
   * Gets all the application tenants.
   * @returns All the application tenants.
   */
  public async getAll(): Promise<LsApplicationTenant[]> {
    const authHeaders = await this.loginService.getHttpAuthorisationHeaders();
    const url = this.env.licenseServerUrl + 'ApplicationTenants';
    const headers = authHeaders.append(CustomHeaders.skipInterceptor, 'true');
    const response = await firstValueFrom(this.http.post<LsApplicationTenantsResponse>(url, {
      ApplicationShortName: this.env.appShortName
    }, {
      headers: headers
    }));
    return response.tenants;
  }

  public async getAuthorizationHeaders(): Promise<HttpHeaders> {
    const license = await this.getLicense();
    const authHeaders = await this.loginService.getHttpAuthorisationHeaders();
    const headers = authHeaders.append('license', license.token);
    return headers;
  }

  public async getApiUrl(): Promise<string> {
    const license = await this.getLicense();
    const tenantConfig = <LsLicenseTenantConfig>JSON.parse(license.tenantConfig);
    return `https://${tenantConfig.apiid}.execute-api.${tenantConfig.region}.amazonaws.com/${tenantConfig.stage}/`
  }

  private async getLicense(): Promise<LsLicense> {
    if (!this.license || this.getIsLicenseExpired(await this.license)) {
      this.license = (async () => {
        const url = this.env.licenseServerUrl + 'ApplicationTenantToken';

        const authHeaders = await this.loginService.getHttpAuthorisationHeaders();
        const headers = authHeaders.append('skip-Interceptor', 'true');

        return await firstValueFrom(this.http.post<LsLicense>(url, {
          ApplicationShortName: this.env.appShortName,
          TenantId: await this.getTenant().then(x => { return x.tenantId })
        }, { headers: headers }));
      })();
    }

    return this.license;
  }

  public async getTenant(): Promise<CognitoSsoConfig> {
    if (!this.tenant) {
      this.tenant = (async () => {
        const email = await this.loginService.getUserEmail();
        const headers = (await this.loginService.getHttpAuthorisationHeaders()).append('skip-Interceptor', 'true');
        const config = await firstValueFrom(this.http.get<CognitoSsoConfig>(
          this.env.licenseServerUrl + `TenantApplications?email=${email}&appName=${this.env.appShortName}`,
          { headers: headers }));
        return config;
      })();
    }

    return await this.tenant;
  }

  private getIsLicenseExpired(license?: LsLicense): boolean {
    if (!license) { return true; }
    const now = new Date();
    const expiryDate = new Date(license.expiryUtc);
    const remainingMilliseconds = expiryDate.getTime() - now.getTime();
    return remainingMilliseconds < LICENSE_EXPIRE_DURATION;
  }
}