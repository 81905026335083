<div class="container">
  <div class="grid">
    <div class="row">
      <div class="col-12">

        <h2 header>{{ 'ASSET_BASED_VALUATIONS_TITLE_TEXT' | translate }}</h2>

        <div body class="loading-panel" *ngIf="(assetsService.fetchingValuationModels$ | async)">
          {{ 'LOADING_ASSET_BASED_VALUATION_MODELS_MESSAGE_TEXT' | translate }}<mis-loading
            type="spinner"></mis-loading>
        </div>

        <div body class="left-margin"
          *ngIf="((assetsService.fetchingValuationModels$ | async) === false) && (assetBasedValuationTableData === null || assetBasedValuationTableData.data === null || assetBasedValuationTableData.data.length === 0)">
          <br />
          {{ 'NO_ASSET_BASED_VALUATION_MODELS_MESSAGE_TEXT' | translate }}
        </div>

        <mat-table body matSort
          *ngIf="(assetsService.valuationModels$ | async) && (assetsService.valuationModels$ | async)!.length > 0 && assetBasedValuationTableData.data.length > 0"
          [dataSource]="assetBasedValuationTableData">

          <ng-container matColumnDef="valuationMethod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'VALUATION_MODEL_METHOD_COLUMN_NAME_TEXT' |
              translate
              }}</th>
            <td mat-cell *matCellDef="let element"> {{element.name }} </td>
          </ng-container>

          <ng-container matColumnDef="includeInValuation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'INCLUDE_IN_VALUATIONS_CHECKBOX_COLUMN_TEXT' |
              translate }}</th>
            <td mat-cell *matCellDef="let element">
              <mis-checkbox size="small" [value]="false" (changed)="includeValuationsChanged(element, $event)">
              </mis-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="generateLetters">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERATE_LETTERS_CHECKBOX_COLUMN_TEXT' | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              <mis-checkbox size="small" [value]="false" *ngIf="element.produceletters && isModelIncluded(element)"
                (changed)="generateLettersChanged(element, $event)"></mis-checkbox>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </mat-table>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2 header>{{ 'RENT_BASED_VALUATIONS_TITLE_TEXT' | translate }}</h2>
        <div body class="loading-panel" *ngIf="(assetsService.fetchingValuationModels$ | async)">
          {{ 'LOADING_RENT_BASED_VALUATION_MODELS_MESSAGE_TEXT' | translate }}<mis-loading type="spinner"></mis-loading>
        </div>

        <div body class="left-margin"
          *ngIf="rentBasedValuationTableData === null || rentBasedValuationTableData.data === null || rentBasedValuationTableData.data.length === 0">
          <br />
          {{ 'NO_RENT_BASED_VALUATION_MODELS_MESSAGE_TEXT' | translate }}
        </div>

        <mat-table body matSort
          *ngIf="(assetsService.valuationModels$ | async) && (assetsService.valuationModels$ | async)!.length > 0 && rentBasedValuationTableData.data.length > 0"
          [dataSource]="rentBasedValuationTableData">

          <ng-container matColumnDef="valuationMethod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'VALUATION_MODEL_METHOD_COLUMN_NAME_TEXT' |
              translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.name }} </td>
          </ng-container>

          <ng-container matColumnDef="includeInValuation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'INCLUDE_IN_VALUATIONS_CHECKBOX_COLUMN_TEXT' |
              translate }}</th>
            <td mat-cell *matCellDef="let element">
              <mis-checkbox size="small" [value]="false"
                (changed)="includeValuationsChanged(element, $event)"></mis-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="generateLetters">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERATE_LETTERS_CHECKBOX_COLUMN_TEXT' | translate
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              <mis-checkbox  size="small" [value]="false" *ngIf="element.produceletters && isModelIncluded(element)"
                (changed)="generateLettersChanged(element, $event)"></mis-checkbox>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </mat-table>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div *ngIf="includeInValuationModelsIds !== null && includeInValuationModelsIds.length > 0">
          <div *ngIf="generateLettersModelIds !== null && generateLettersModelIds.length > 0">
            <h4>{{ 'INDICATIVE_COST_LABEL_WITH_LETTERS_TEXT' | translate: {numberOfAssets: selectedAssetIds.length+ ' '
              +
              (selectedAssetIds.length > 1 ? ('ASSETS_PLURAL_TEXT' | translate) : ('ASSET_SINGULAR_TEXT' | translate))}
              }}{{estimatedCost.toFixed(2)}}</h4>
          </div>
          <div *ngIf="generateLettersModelIds !== null && generateLettersModelIds.length === 0">
            <h4>{{ 'INDICATIVE_COST_LABEL_WITHOUT_LETTERS_TEXT' | translate: {numberOfAssets: selectedAssetIds.length +
              '
              ' + (selectedAssetIds.length > 1 ? ('ASSETS_PLURAL_TEXT' | translate) : ('ASSET_SINGULAR_TEXT' |
              translate))} }}{{estimatedCost.toFixed(2)}}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="error" *ngIf="lastRunError.length > 0">{{ lastRunError }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="buttons">
          <button misButton class="valueAssetsButton" buttonColor="success" buttonSize="x-small" [disabled]="!isAnyModelIncluded()"
            (click)="runValuation()">{{
            'RUN_VALUATION_BUTTON_TEXT' | translate }}
          </button>
          <button class="valueAssetsButton left-margin" misButton buttonSize="x-small" buttonColor="info" buttonStyle="solid"
            (clickFn)="close()">
            {{ 'CLOSE_BUTTON_TEXT' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>