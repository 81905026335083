import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelCase' })
export class CamelCasePipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return value;
    }

    // Trim leading and trailing whitespace
    const trimmedValue = value.trim();

    // Check if the trimmed string is a single word without delimiters
    if (!/[\s-_+]/.test(trimmedValue)) {
      return trimmedValue;
    }

    return trimmedValue
      .toLowerCase()
      .split(/[\s-_+]+/)
      .map((word, index) =>
        index === 0 ? word : word[0].toUpperCase() + word.slice(1)
      ).join('');
  }
}
