import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'mis-component-library';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { AssetsService } from 'src/app/services/assets/assets.service';
import { ValuationModel } from 'src/types/valuation-model';
import { TranslateService } from '@ngx-translate/core';
import { ValuationService } from 'src/app/services/valuation/valuation.service';
import { ValuationRunOutput } from 'src/types/valuation-run-output';
import { ValuationCompleteDialogComponent } from '../valuation-complete-dialog/valuation-complete-dialog.component';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-value-assets',
  templateUrl: './value-assets.component.html',
  styleUrls: ['./value-assets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValueAssetsComponent implements OnInit, OnDestroy {

  @Input() public selectedAssetIds = [];
  @Input() public allAssetsSelected = false;

  @Output() public estimatedCost: number = 0;

  public disableValuationButton = true;
  public valuationInProgress = false;
  public wasValuationRun = false;
  public lastRunError = '';
  @Output() public valuationRunDownloadUrl: string | null = null;

  private unsubscribe$ = new Subject<void>();
  private timeoutGetLastValuationStatus!: NodeJS.Timeout;

  public assetBasedValuationTableData: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public rentBasedValuationTableData: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  public includeInValuationModelsIds: number[] = [];
  public generateLettersModelIds: number[] = [];

  public valuationModels: ValuationModel[] = [];

  public columns: string[] = [
    'valuationMethod',
    'includeInValuation',
    'generateLetters'
  ];

  constructor(
    public assetsService: AssetsService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private valuationService: ValuationService,
  ) {
  }

  public ngOnInit(): void {
    combineLatest([
      this.assetsService.valuationModels$
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(([models]) => {

      if (models.length > 0) {

        this.valuationModels = models;

        this.assetBasedValuationTableData.data = models.filter(model => {
          return model.valuationtype.toString() === 'AssetBased';
        });

        this.rentBasedValuationTableData.data = models.filter(model => {
          return model.valuationtype.toString() === 'RentBased';
        });

        this.cdr.detectChanges();
      }
    });
  }

  public calculateEstimatedCost(): void {

    this.estimatedCost = 0;

    if (this.selectedAssetIds != null &&
      this.selectedAssetIds.length > 0 &&
      this.includeInValuationModelsIds != null &&
      this.includeInValuationModelsIds.length > 0 &&
      this.valuationModels.length != null) {

      this.valuationModels.forEach(model => {

        if (this.includeInValuationModelsIds.indexOf(model.id) >= 0) {
          this.estimatedCost += model.ppv;
        }

        if (this.generateLettersModelIds.indexOf(model.id) >= 0) {
          if (model.ppl) {
            this.estimatedCost += model.ppl;
          }
        }
      });

      this.estimatedCost = this.selectedAssetIds.length * (this.estimatedCost / 100);

      this.cdr.detectChanges();
    }
  }

  public includeValuationsChanged(
    valuationModel: ValuationModel,
    isIncludeInValuation: boolean): void {

    this.valuationRunDownloadUrl = null;

    const isIncludedAlready = this.includeInValuationModelsIds.find(modelId => {
      return modelId === valuationModel.id;
    });

    if (isIncludeInValuation) {
      if (!isIncludedAlready) {
        // if it's to be included and hadn't been already, add it
        this.includeInValuationModelsIds.push(valuationModel.id);
      }
    }
    else {
      if (isIncludedAlready) {
        // If it's changed to be not included, and was included before, remove it
        this.includeInValuationModelsIds =
          this.includeInValuationModelsIds.filter(modelId => {
            return modelId !== valuationModel.id;
          });
        this.generateLettersChanged(valuationModel, false);
      }
    }

    this.calculateEstimatedCost();
  }

  public isModelIncluded(valuationModel: ValuationModel): boolean {
    return this.isAnyModelIncluded() &&
      this.includeInValuationModelsIds.includes(valuationModel.id);
  }

  public generateLettersChanged(
    valuationModel: ValuationModel,
    isGenerateLetter: boolean): void {

    this.valuationRunDownloadUrl = null;

    const isGenerateLettersAlready = this.generateLettersModelIds.find(modelId => {
      return modelId === valuationModel.id;
    });

    if (isGenerateLetter) {
      if (!isGenerateLettersAlready) {
        // if letters are to be generated and was not before,
        // then add id to list of models to generate letters for
        this.generateLettersModelIds.push(valuationModel.id);
      }
    }
    else {
      if (isGenerateLettersAlready) {
        // If it's changed to not generate letters, and was before, remove it
        this.generateLettersModelIds =
          this.generateLettersModelIds.filter(modelId => {
            return modelId !== valuationModel.id;
          });
      }
    }

    this.calculateEstimatedCost();
  }

  public isAnyModelIncluded(): boolean {
    return (this.includeInValuationModelsIds &&
      this.includeInValuationModelsIds.length > 0);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public runValuation(): void {
    this.wasValuationRun = true;
    this.valuationInProgress = true;
    this.valuationRunDownloadUrl = null;

    this.dialogService.open({
      title: this.translate.instant("RUN_VALUATION_TEXT"),
      component: LoadingDialogComponent,
      backdropDismiss: false,
      props: {
        dialogText: this.translate.instant('RUNNING_VALUATION_TEXT')
      }
    })

    this.assetsService.valueAssets(
      this.includeInValuationModelsIds,
      this.generateLettersModelIds,
      this.allAssetsSelected,
      this.selectedAssetIds
    ).then((resp) => {
      const valuationRunId = resp.runid;
      if (this.generateLettersModelIds && this.generateLettersModelIds.length > 0) {
        // If letters gen requested, 
        // get the valuation run output, and store the download url (s3 key)
        // This can be used on clicking the download button, 
        // to get the presigned download url
        this.valuationService.getValuationRunOutput(valuationRunId);
        this.valuationService.valuationRunOutputs$.pipe(takeUntil(this.unsubscribe$)).subscribe((valuationRunOutputs: ValuationRunOutput[]) => {
          if (valuationRunOutputs != null &&
            valuationRunOutputs.length > 0 &&
            valuationRunOutputs[0].outputUrl != null &&
            valuationRunOutputs[0].outputUrl != ''
          ) {
            this.valuationRunDownloadUrl = valuationRunOutputs[0].outputUrl;
            this.cdr.detectChanges();
          }
        });
      }

      this.dialogService.open({
        title: this.translate.instant('RUN_VALUATION_TEXT'),
        component: ValuationCompleteDialogComponent,
        props: {
          valuationRunId: resp.runid,
          selectedAssetIds: this.selectedAssetIds
        }
      }).then(() => {
        this.assetsService.getAssets();
      });

      this.cdr.detectChanges();
    });
  }

  public close(): void {
    if (this.timeoutGetLastValuationStatus != null) {
      clearTimeout(this.timeoutGetLastValuationStatus)
    }
    this.dialogService.close(this.wasValuationRun)
  }

  public isValuationRunDownloadUrl(): boolean {
    return this.valuationRunDownloadUrl != null &&
      this.valuationRunDownloadUrl != '';
  }

}