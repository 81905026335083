import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from './pages/not-found/not-found-page.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginPathConfig } from 'login/lib/types/login-config';
import { environment } from 'src/environments/environment';
import { LoginRoutingModule } from 'login';
import { ImportGuideComponent } from './components/import-guide/import-guide.component';
import { NotAuthorisedPageComponent } from './pages/not-authorised/not-authorised-page.component';

export const DEV_LOGIN_CONFIG: LoginPathConfig = {
  loginPath: 'login',
  newPasswordPath: 'login/new-password'
};

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  redirectTo: 'app/assets'
}, {
  canActivate: [AuthGuard.canLoadAppGuard],
  loadChildren: () => import('./framework/framework.module').then((m) => m.FrameworkModule),
  path: 'app',
  title: 'Reval'
}, {
  component: ImportGuideComponent,
  path: 'help'
}, {
  component: NotAuthorisedPageComponent,
  path: 'not-authorised'
}];

if (!environment.loginHost) {
  routes.push(...LoginRoutingModule.getRoutes(DEV_LOGIN_CONFIG));
}

routes.push({
  path: '**',
  component: NotFoundPageComponent,
  title: 'Reval - Page Not Found'
});

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }