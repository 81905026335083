import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BatchUploadStatusItem } from 'src/types/batch-upload-status';



@Component({
  selector: 'app-upload-error-dialog',
  templateUrl: './upload-error-dialog.component.html',
  styleUrl: './upload-error-dialog.component.scss'
})
export class UploadErrorDialogComponent implements OnInit {
  @Input() public statusItem: BatchUploadStatusItem | undefined;
  public errorTableData: MatTableDataSource<{ error: string, row: number }> = new MatTableDataSource<{ error: string, row: number }>([]);

  public columns: string[] = [
    'row',
    'error'
  ];

  public ngOnInit(): void {
    const errors: { error: string, row: number }[] = [];
    const lines = this.statusItem?.detail.split('\n') ?? [];
    for (const line of lines) {
      const [errorPart, rowPart] = line.split(/ \((\d+)\)/);
      const error = errorPart.trim();
      const row = parseInt(rowPart);
      if (error != '' &&  row != undefined){
        errors.push({ error, row });
      }
    }
    this.errorTableData.data = errors;
  }

}
