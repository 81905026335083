// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from "src/types/environment";

export const environment: Environment = {
  production: false,
  appShortName: 'Reval',
  licenseServerUrl: "https://iyj3ipjim2.execute-api.eu-west-2.amazonaws.com/Prod/",
  loginHost: "https://dev.cloud.incline-it.com",
  cognitoConfig: {
    userPoolId: "eu-west-2_3J9uwiJjm",
    userPoolClientId: "52tjdpv066unkb1lhbaa2df649",
    loginWith: {
      email: true,
      oauth: {
        domain: "dev-cloud.auth.eu-west-2.amazoncognito.com",
        scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: ["https://dev.activef.cloud.incline-it.com"],
        redirectSignOut: ["https://dev.activef.cloud.incline-it.com"],
        responseType: 'code'
      }
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
