import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { TenantService } from "../tenant/tenant.service";
import { GetUserRoleResponse, UserRole } from "src/types/user-role";

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  private userRole: BehaviorSubject<UserRole|null> = new BehaviorSubject<UserRole|null>(null);
  public userRole$ = this.userRole.asObservable();

  private readonly fetchingUserRole = new BehaviorSubject<boolean>(true);
  public fetchingUserRole$ = this.fetchingUserRole.asObservable()
  public isUserRoleFetched = false;

  constructor(
    private http: HttpClient,
    private tenantService: TenantService
  ) { }

  public async getUserRole(): Promise<void> {
    this.fetchingUserRole.next(true);

    const url = await this.tenantService.getApiUrl();
    const response = await firstValueFrom(this.http.get<GetUserRoleResponse>(`${url}UserRole`));

    if(response != null && response.userRole != null)
    {
      this.isUserRoleFetched = true;
      this.userRole.next(response.userRole);
    }

    this.fetchingUserRole.next(false);
  }
}