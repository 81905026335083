<div class="container">
    {{ "VALUATION_RAN_TEXT" | translate }}

    <div class="row">

        <table class="col-12">
            <tr>
                <th>{{ "VALUATION_COMPLETE.DATE" | translate }}</th>
                <td>{{ valuationRunOutput?.outputTimestamp | date:'dd/MM/yyyy' }}</td>
            </tr>
            <tr>
                <th>{{ "VALUATION_COMPLETE.ASSET_COUNT" | translate }}</th>
                <td>{{ valuationRunOutput?.numberOfAssets }}</td>
            </tr>
            <tr>
                <th>{{ "VALUATION_COMPLETE.COST" | translate }}</th>
                <div>{{ (valuationRunOutput?.cost ? valuationRunOutput!.cost! / 100 : 0) | currency: 'GBP' : 'symbol':'1.2-2' }}</div>
            </tr>
            <tr>
                <th>{{ "VALUATION_COMPLETE.METHODS" | translate }}</th>
                <td>{{ getValuationRunMethodNames() }}</td>
            </tr>
            <tr *ngIf="valuationRunOutput?.outputUrl">
                <th>{{ "VALUATION_COMPLETE.LETTERS"| translate }}</th>
                <td>
                    <div>
                        <button *ngIf="valuationRunOutput?.outputUrl !== null && valuationRunOutput?.outputUrl !== ''"
                            misButton buttonSize="x-small" buttonColor="primary"
                            buttonStyle="solid" (clickFn)="downloadValuationOutputs()">
                            {{ 'DOWNLOAD_VALUATION_OUTPUT_TEXT' | translate }}
                        </button>
                    </div>
                </td>
            </tr>
        </table>

        <div class="col-12">
            <div class="buttons">
                <button misButton buttonColor="success" buttonSize="x-small"
                    (click)="startNewValuation()">{{'VALUATION_COMPLETE.NEW_VALUATION_BUTTON_TEXT' | translate }}
                </button>
                <button class="left-margin" misButton buttonSize="x-small" buttonColor="info" buttonStyle="solid"
                    (clickFn)="close()">
                    {{ 'CLOSE_BUTTON_TEXT' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>