import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, switchMap } from 'rxjs';
import { CustomHeaders } from 'src/types/custom-headers';
import { TenantService } from '../tenant/tenant.service';

@Injectable()
export class ApiIntecerceptorService implements HttpInterceptor {
  
  constructor(private tenantService: TenantService) {  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.headers.has(CustomHeaders.skipInterceptor)) { 
      const apiRequest = req.clone({ headers: req.headers.delete(CustomHeaders.skipInterceptor) });
      return next.handle(apiRequest);
    }

    return from(this.tenantService.getAuthorizationHeaders()).pipe(
      switchMap(authHeaders => {
        const existingHeaders = req.headers;
        let combinedHeaders = new HttpHeaders();
        authHeaders.keys().forEach(key => combinedHeaders = combinedHeaders.set(key, authHeaders.get(key) ?? ""));
        existingHeaders.keys().forEach(key => combinedHeaders = combinedHeaders.set(key, existingHeaders.get(key) ?? ""));

        const apiRequest = req.clone({ headers: combinedHeaders });
        return next.handle(apiRequest);
      })
    )
  }
}