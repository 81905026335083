import { DatePipe } from '@angular/common';
import { Valuation } from 'src/types/asset';

export class AssetViewModel {
  public id: number;
  public externalReference: string;
  public propertyType: string;
  public conditionType: string;
  public financeInstrument: string;
  public owningBody: string;
  public tenantRights: string;
  public currentValuationValue: number;
  public currentValuationDate: Date;
  public currentValuationType: string;
  public buildDate: Date;
  public constructionType: string;
  public bedrooms: number;
  public line1: string;
  public line2: string;
  public line3: string;
  public line4: string;
  public line5: string;
  public region: string;
  public postcode: string;
  public country: string;
  public internalAreaSqFt: number | null;
  public assetOrigin: string;
  public originalPrice: number;
  public originalPriceDate: Date;
  public dateReceived: Date;
  public outstandingRepairsCost: number;
  public marketRent: number | null;
  public achievedRent: number | null;
  public rightToBuyYears: number | null;
  public tenantRightsQualificationDate: Date;
  public datePipe: DatePipe;
  public lastValuationDisplayText: string;
  public pendingValuationDisplayText: string;
  public sharedOwnershipPercent: number | null;
  public gardenSize: string;
  public significantView: string;
  public rtaDiscountLocation: string;

  public get lastValuation(): Valuation | null {
    if (this.pendingValuations.length > 0) {
      return this.pendingValuations[this.pendingValuations.length - 1]
    }
    else {
      return null;
    }
  }
  public selected?: boolean;
  public pendingValuations: Valuation[];

  constructor(
    _id: number,
    _externalReference: string,
    _propertyType: string,
    _conditionType: string,
    _financeInstrument: string,
    _owningBody: string,
    _tenantRights: string,
    _currentValuationValue: number,
    _currentValuationDate: string | number | Date,
    _currentValuationType: string,
    _buildDate: string | number | Date,
    _constructionType: string,
    _bedrooms: number,
    _line1: string,
    _line2: string,
    _line3: string,
    _line4: string,
    _line5: string,
    _region: string,
    _postcode: string,
    _country: string,
    _internalAreaSqFt: number| null,
    _assetOrigin: string,
    _originalPrice: number,
    _originalPriceDate: string | number | Date,
    _dateReceived: string | number | Date,
    _outstandingRepairsCost: number,
    _marketRent: number | null,
    _achievedRent: number | null,
    _rightToBuyYears: number | null,
    _tenantRightsQualificationDate: string,
    _sharedOwnershipPercent: number | null,
    _gardenSize: string,
    _significantView: string,
    _rtaDiscountLocation: string
  ) {

    this.datePipe = new DatePipe('en-GB');

    this.id = _id;
    this.externalReference = _externalReference;
    this.postcode = _postcode;
    this.region = _region;
    this.country = _country;
    this.propertyType = _propertyType;
    this.conditionType = _conditionType;
    this.financeInstrument = _financeInstrument;
    this.owningBody = _owningBody;
    this.tenantRights = _tenantRights;
    this.currentValuationValue = _currentValuationValue;
    this.currentValuationDate = new Date(_currentValuationDate);
    this.currentValuationType = _currentValuationType;
    this.buildDate = new Date(_buildDate);
    this.constructionType = _constructionType;
    this.bedrooms = _bedrooms;
    this.line1 = _line1;
    this.line2 = _line2;
    this.line3 = _line3;
    this.line4 = _line4;
    this.line5 = _line5;
    this.internalAreaSqFt = _internalAreaSqFt;
    this.assetOrigin = _assetOrigin;
    this.originalPrice = _originalPrice;
    this.originalPriceDate = new Date(_originalPriceDate);
    this.dateReceived = new Date(_dateReceived);
    this.outstandingRepairsCost = _outstandingRepairsCost;
    this.marketRent = _marketRent;
    this.achievedRent = _achievedRent;
    this.rightToBuyYears = _rightToBuyYears;
    this.tenantRightsQualificationDate = new Date(_tenantRightsQualificationDate);
    this.pendingValuations = [];
    this.lastValuationDisplayText = '';
    this.pendingValuationDisplayText = '';
    this.selected = false;
    this.sharedOwnershipPercent = _sharedOwnershipPercent;
    this.gardenSize = _gardenSize;
    this.significantView = _significantView;
    this.rtaDiscountLocation = _rtaDiscountLocation;
  }

  public formatDateForExport(dateObj: Date): string | null {
    if (dateObj === null) {
      return "";
    }
    else {
      return this.datePipe.transform(dateObj, 'dd/MM/yyyy');
    }
  }

  public getRightToBuyYearsText(): string {
    if (this.rightToBuyYears === null) {
      return ""
    }
    else {
      const datePipeString = this.datePipe.transform(this.tenantRightsQualificationDate, 'MMM yyyy');
      return this.rightToBuyYears + " ( " + datePipeString + " )";
    }
  }

  public getGardenSizeText(): string {
    if (this.gardenSize === null) {
      return ""
    }
    else {
      if(this.gardenSize === 'None')  {
        return "None";
      }
      // eslint-disable-next-line no-magic-numbers
      else if(this.gardenSize == 'LessThanZeroPointOneAcres') {
        return "< 0.1 acres";
      }
      // eslint-disable-next-line no-magic-numbers
      else if(this.gardenSize == 'ZeroPointOneToPointFiveAcres') {
        return "0.1 to 0.5 acres";
      }
      // eslint-disable-next-line no-magic-numbers
      else if(this.gardenSize == 'ZeroPointFiveToOneAcres') {
        return "0.5 to 1 acres";
      }
      // eslint-disable-next-line no-magic-numbers
      else if(this.gardenSize == 'AboveOneAcres') {
        return "Above 1 acre";
      }
      else  {
        return "";
      }
    }
  }
}