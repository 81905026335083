<mis-page [title]="'CONFIGURATION.USERS.USER_DETAILS' | translate">
    <ng-container controls>
        <div class="buttons">
            <button id="save" misButton buttonSize="x-small" (click)="save()" [disabled]="!form.valid">{{ 'SAVE_BUTTON_TEXT' | translate }}</button>
        </div>
    </ng-container>
    <form [formGroup]="form" *ngIf="!isLoading; else loading">
        <mis-card type="primary">
            <h3 header>{{isEditing ? "Edit" : "New"}}</h3>
            <div body>
                <mis-input formControlName="title" [label]="'CONFIGURATION.USERS.TITLE' | translate"></mis-input>
                <mis-input formControlName="firstName" [label]="'CONFIGURATION.USERS.FIRSTNAME' | translate"></mis-input>
                <mis-input formControlName="surname" [label]="'CONFIGURATION.USERS.SURNAME' | translate"></mis-input>
                <mis-input formControlName="telephone" [label]="'CONFIGURATION.USERS.TELEPHONE' | translate"></mis-input>
                <mis-input formControlName="mobile" [label]="'CONFIGURATION.USERS.MOBILE' | translate"></mis-input>
                <mis-input formControlName="email" [label]="'CONFIGURATION.USERS.EMAIL' | translate"></mis-input>
                <!-- user role selection?  -->
                 <!-- TODO: only show this if tenant is non-SSO, get UserRoles from server  -->
                <!-- <mis-select formControlName="role" value="all" 
                    [options]="[{ text: 'User', value: '3' }, { text: 'User', value: '1' }]">
                </mis-select> -->
                <mis-checkbox size="small" formControlName="active" [label]="'CONFIGURATION.USERS.ACTIVE' | translate"></mis-checkbox>
            </div>
        </mis-card>
    </form>
    <ng-template #loading>
        <mis-loading type="spinner" class="center-loading"></mis-loading>
    </ng-template>
</mis-page>