<div class="container">
    <div class="row">
        <div class="col-12">
    <mat-table body matSort
        [dataSource]="errorTableData">
    
        <ng-container matColumnDef="row">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'UPLOAD_ERROR_ROW_COLUMN_HEADER' |
                translate}}</th>
            <td mat-cell *matCellDef="let element"> {{element.row }} </td>
        </ng-container>
    
        <ng-container matColumnDef="error">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'UPLOAD_ERROR_MESSAGE_COLUMN_HEADER' |
                translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.error }} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
    </mat-table>
        </div>
    </div>
</div>