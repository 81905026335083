import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DialogService, ToastService } from 'mis-component-library';
import { Subject, takeUntil } from 'rxjs';
import { ValuationService } from 'src/app/services/valuation/valuation.service';
import { ValuationRunOutput } from 'src/types/valuation-run-output';
import { ValueAssetsComponent } from '../value-assets/value-assets.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-valuation-complete-dialog',
  templateUrl: './valuation-complete-dialog.component.html',
  styleUrl: './valuation-complete-dialog.component.scss'
})
export class ValuationCompleteDialogComponent implements OnInit, OnDestroy {

  @Input() public valuationRunId: number | undefined;
  @Input() public selectedAssetIds: number[] = [];
  public valuationRunOutput: ValuationRunOutput | undefined;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef, 
    private dialogService: DialogService,
    private valuationService: ValuationService,
    private toastService: ToastService,
    private translateService: TranslateService
  ){ }

  public ngOnInit(): void {
    if (this.valuationRunId === undefined) { return; }

    this.valuationService.getValuationRunOutput(this.valuationRunId);
    this.valuationService.valuationRunOutputs$.pipe(takeUntil(this.unsubscribe$)).subscribe((valuationRunOutputs: ValuationRunOutput[]) => {
      if (valuationRunOutputs != null &&
        valuationRunOutputs.length > 0
      ) {
        this.valuationRunOutput = valuationRunOutputs[0];
        this.cdr.detectChanges();
      }
    });
  }

  public getValuationRunMethodNames(): string {
    const methodNames = this.valuationRunOutput?.valuationModelIdNames.map(name => name.item2);
    console.log(methodNames);
    return methodNames?.join(', ') ?? '';
  }

  public downloadValuationOutputs(): void {
    if (this.valuationRunOutput?.outputUrl != null &&
      this.valuationRunOutput?.outputUrl != ''
    ) {
      // using the valuationRunDownloadUrl (s3 key), 
      // get the presigned download url
      // and use it to download the output files
      this.valuationService.getPresignedDownloadUrl(this.valuationRunOutput?.outputUrl?.valueOf());
      this.valuationService.presignedDownloadUrl$.pipe(takeUntil(this.unsubscribe$)).subscribe(async (presignedDownloadUrl: string) => {
        if (presignedDownloadUrl != null &&
          presignedDownloadUrl != '' &&
          presignedDownloadUrl.length > 0) {
          const blob = await this.valuationService.getValuationOutputFile(presignedDownloadUrl);

          window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = presignedDownloadUrl;
          anchor.download = 'valuation-letters.zip';
          anchor.click();
          window.URL.revokeObjectURL(presignedDownloadUrl);
        }
      });
    }
  }

  public startNewValuation(): void {
    if (this.selectedAssetIds.length > 0){

      this.dialogService.open({
        title: this.translateService.instant('VALUE_ASSETS_DIALOG_TITLE'),
        component: ValueAssetsComponent,
        props: {
          selectedAssetIds: this.selectedAssetIds
        }
      });
    }
    else {
      this.toastService.show(this.translateService.instant('ERROR_TEXT'), this.translateService.instant(''));
    }
  }

  public close(): void {
    this.dialogService.close(true);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
