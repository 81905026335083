import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TenantService } from '../tenant/tenant.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AppUser, AppUserPostRequest, AppUserPostResponse } from 'src/types/app-user';
import { Environment } from 'src/types/environment';
import { ENV } from 'src/providers/environment.provider'

type LicensedUser = { id: string; email: string; tenantId: string; };

@Injectable({
  providedIn: 'root'
})
export class AppUserService {

  private readonly creatingAppUser = new BehaviorSubject<boolean>(true);
  public creatingAppUser$ = this.creatingAppUser.asObservable();

  constructor(
    @Inject(ENV) private readonly environment: Environment,
    private readonly http: HttpClient,
    private readonly tenantService: TenantService) {
  }

  public async getAll(): Promise<AppUser[]> {
    const apiUrl = await this.tenantService.getApiUrl();
    const url = apiUrl + 'users';
    const appUsers = await firstValueFrom(this.http.get<AppUser[]>(url));
    return appUsers;
  }

  public async get(id: number | string): Promise<AppUser> {
    const [headers, url] = await Promise.all([
      this.tenantService.getAuthorizationHeaders(),
      this.tenantService.getApiUrl().then(url => { return `${url}users/${id}`; })
    ]);

    const user = await firstValueFrom(this.http.get<AppUser>(url, { headers: headers }));
    return user;
  }

  public async create(user: Omit<AppUser, 'id' | 'licensedUserId' | 'isSSO'>): Promise<AppUser> {
    const [headers, url, tenant] = await Promise.all([
      this.tenantService.getAuthorizationHeaders(),
      this.tenantService.getApiUrl().then(url => { return `${url}`; }),
      this.tenantService.getTenant()
    ]);

    // Ensure the users is created in the license server first
    const licensedUser = await firstValueFrom(this.http.post<LicensedUser>(this.environment.licenseServerUrl + 'Users', 
      { email: user.email, tenantId: tenant.tenantId }, { headers: headers }));

    // Add the user to app users
    // The value of the sso flag, comes from the tenant to the post request:
    return await this.createAppUser(user, 
      url, 
      licensedUser.id,
      tenant.sso,
      headers
    );  
  }

  public async createAppUser(
    user: Omit<AppUser, 'id' | 'licensedUserId' | 'isSSO'>, 
    url: string, 
    licensedUserId: string,
    isSSO: boolean,
    headers: HttpHeaders) : Promise<AppUser> {
    
    this.creatingAppUser.next(true);

    const appUserPostRequest: AppUserPostRequest = {
      appUser: {
        email: user.email,
        title: user.title,
        firstName: user.firstName,
        surname: user.surname,
        telephone: user.telephone,
        mobile: user.mobile,
        active: user.active,
        role: user.role,
        isSSO: isSSO
      }
    };

    const postResponse = await firstValueFrom(this.http.post<AppUserPostResponse>(`${url}Users`, appUserPostRequest, 
      { headers: headers }));
    this.creatingAppUser.next(false);

    console.log(postResponse.result);

    // TODO: get the appUser from the response, not request, 
    // populated with the newly created appUser.id

    return appUserPostRequest.appUser;
  }
}