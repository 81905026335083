import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify } from 'aws-amplify';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

if (environment.production) {
  enableProdMode();
}

// Configure amplify 
Amplify.configure({ Auth: { Cognito: environment.cognitoConfig } });
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ sameSite: 'none' }));

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));