import { Component, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: 'app-import-guide',
  styleUrls: ['./import-guide.component.scss'],
  templateUrl: './import-guide.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ImportGuideComponent {

}