import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs'
import { GetValuationRunStatusResponse, ProcessValuationResponse, ValuationModel, ValuationPeriod, ValuationRunResponse } from 'src/types/valuation-model';
import { TenantService } from '../tenant/tenant.service';
import { GetAssetResponse, GetValuationsForAssetIdResponse, Valuation } from 'src/types/asset';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AssetViewModel } from 'src/app/viewmodel/asset';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  // Observable-Subject pairs
  private readonly valuationRunCreated = new Subject<void>();
  public get valuationRunCreated$(): Observable<void> {
    return this.valuationRunCreated;
  }

  private readonly valuationModels = new BehaviorSubject<ValuationModel[]>([]);
  public get valuationModels$() : Observable<ValuationModel[]> {
    return this.valuationModels;
  }

  private readonly valuationPeriods = new BehaviorSubject<ValuationPeriod[]>([]);
  public get valuationPeriods$() : Observable<ValuationPeriod[]> {
    return this.valuationPeriods;
  }

  private readonly assets = new BehaviorSubject<AssetViewModel[]>([]);
  public get assets$(): Observable<AssetViewModel[]> {
    return this.assets;
  }

  // Status observable-subject pairs
  private readonly fetchingAssets = new BehaviorSubject<boolean>(true);
  public get fetchingAssets$() : Observable<boolean> {
    return this.fetchingAssets;
  }

  private readonly fetchingValuationModels = new BehaviorSubject<boolean>(true);
  public get fetchingValuationModels$(): Observable<boolean> {
    return this.fetchingValuationModels;
  }

  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe
  ) { }

  public async getAssets(): Promise<void> {
    this.fetchingAssets.next(true);
    const url = await this.tenantService.getApiUrl();
    const assetData = await firstValueFrom(this.http.get<GetAssetResponse>(`${url}RetrieveAssets`));
    this.assets.next(this.getAssetsResponse(assetData));
    this.fetchingAssets.next(false);
  }

  public async loadValuationModels(): Promise<void> {
    this.fetchingValuationModels.next(true);
    const url = await this.tenantService.getApiUrl();
    const modelResponse = await firstValueFrom(this.http.get<ProcessValuationResponse>(`${url}ValuationModels`));
    this.valuationModels.next(modelResponse.models);
    this.valuationPeriods.next(modelResponse.valuationindexperiods);
    this.fetchingValuationModels.next(false);
  }

  public async valueAssets(
    modelIds: number[], 
    letterModelIds: number[], 
    allAssets: boolean, 
    assetIds: number[]): Promise<ValuationRunResponse> {
    const url = await this.tenantService.getApiUrl();
    const body = {
      modelIds: modelIds,
      letterModelIds: letterModelIds,
      allassets: allAssets,
      assetIds: assetIds
    };
    return await firstValueFrom(this.http.post<ValuationRunResponse>(`${url}Valuation`, body));
  }

  public async getValuationsForAssetId(assetId: number): Promise<Valuation[]> {
      const url = await this.tenantService.getApiUrl();
      const body = {
        assetId
      };
      return (await firstValueFrom(this.http.post<GetValuationsForAssetIdResponse>(`${url}RetrieveAssetValuations`, body))).pendingValuations;
  }

  public async getValuationRunStatus(runId: number): Promise<GetValuationRunStatusResponse> {
    const url = await this.tenantService.getApiUrl();
    const body = {
      runid: runId
    };
    const resp = await firstValueFrom<GetValuationRunStatusResponse>(this.http.post<GetValuationRunStatusResponse>(`${url}ValuationStatus`, body));
    if (resp.status.length > 0 && resp.status[0].runId < runId || resp.status.length === 0 && runId > 0) {
      resp.pendingRuns = true;
    }
    return resp;
  }

  private getAssetsResponse(resp: GetAssetResponse): AssetViewModel[] {
    const results: AssetViewModel[] = [];
    for (const element of resp.assets) {
      const asset = element;
      const assetVM = new AssetViewModel(
        asset.id,
        asset.externalReference,
        asset.propertyType,
        asset.conditionType,
        asset.financeInstrument,
        asset.owningBody,
        asset.tenantRights,
        asset.currentValuation.value,
        asset.currentValuation.date,
        asset.currentValuation.type,
        asset.buildDate,
        asset.constructionType,
        asset.bedrooms,
        asset.address.line1,
        asset.address.line2,
        asset.address.line3,
        asset.address.line4,
        asset.address.line5,
        asset.address.region,
        asset.address.postcode,
        asset.address.country,
        asset.internalAreaSqFt,
        asset.assetOrigin,
        asset.originalPrice,
        asset.originalPriceDate,
        asset.dateReceived,
        asset.outstandingRepairsCost,
        asset.marketRent,
        asset.achievedRent,
        asset.rightToBuyYears,
        asset.tenantRightsQualificationDate,
        asset.sharedOwnershipPercent,
        asset.gardenSize,
        asset.significantView,
        asset.rtaDiscountLocation
      );

      if (asset.pendingValuations != null) {
        for (const element of asset.pendingValuations) {
          const pendingVal = element;
          assetVM.pendingValuations.push(pendingVal);
        }
      }

      assetVM.lastValuationDisplayText = this.generateValueDisplayText(asset.currentValuation.value, asset.currentValuation.date, asset.currentValuation.type);
      if (assetVM.lastValuation) {
        assetVM.pendingValuationDisplayText = this.generateValueDisplayText(assetVM.lastValuation.value, assetVM.lastValuation.timestamp, assetVM.lastValuation.type);
      } else {
        assetVM.pendingValuationDisplayText = '';
      }
      results.push(assetVM);
    }
    return results;
  }

  private generateValueDisplayText(value: number, date: string | Date, type: string): string {
    return [
      `${this.currencyPipe.transform(value, 'GBP', 'symbol', '1.0-0')}`,
      `${this.datePipe.transform(date, 'MMM yyyy')}`,
      `${type}`
    ].join(' ');
  }
}
